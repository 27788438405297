@import "../../tools";

.search {
  display: flex;
  width: 100%;
  max-width: 675px;
  position: relative;

  &__icon {
    position: absolute;
    width: 15px;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    //&__warning {
    //  right: 40px;
    //}
  }

  &__options {
    &__icon {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
    }

    &__content {
      padding: 25px 20px 15px;

      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 10px;

        & > div {
          cursor: pointer;
          margin-right: 10px;
          font-size: 0.9rem;
          padding: 5px;
          font-weight: 500;
          @include color(color("gray-400"), color("gray-500"));

          &:last-of-type {
            margin-right: 0;
            @include color(color("emerald-500"), color("emerald-500"));
          }
        }
      }
    }

    & > [data-tippy-root] {
      width: 100%;
    }
  }

  &__input-container {
    display: flex;
    width: 100%;
    position: relative;
    margin: 0 2px 0 0;

    //&__suggestions {
    //  position: absolute;
    //  top: 100%;
    //  width: 100%;
    //  @include background(color("gray-600"), #fff);
    //  box-shadow: 0 3px 6px #00000033;
    //  padding: 12px 15px;
    //  margin-top: 7px;
    //
    //  &__item {
    //    font-size: 0.875rem;
    //    font-weight: 500;
    //    margin: 8px 0;
    //    cursor: pointer;
    //
    //    &--selected {
    //      color: color("emerald-500");
    //    }
    //
    //    &:hover {
    //      color: color("emerald-500");
    //    }
    //  }
    //}
  }

  &__input {
    width: 100%;
    flex-grow: 1;
    font-family: inherit;
    font-size: 1rem;
    color: inherit;
    @include background(color("gray-900"), color("gray-100"));
    border: 1px solid transparent;
    border-radius: 0;
    padding: 8px 70px 8px 35px;

    &:focus {
      outline: none;
      border-color: color("emerald-500");
    }

    //&__warning {
    //  &:focus {
    //    border-color: #ffae00;
    //  }
    //}
  }

  &__clear-input {
    position: absolute;
    width: 12px;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
  }
}
