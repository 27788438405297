@import "../tools";

.container {
  display: flex;
  flex-grow: 1;
}

.aside {
  @include background(color("gray-700"), color("white"));
  margin-left: -$aside-width;
  min-width: $aside-width;
  max-width: $aside-width;
  border-right: 1px solid;
  @include border-color(color("gray-900"), color("gray-200"));
  transition: margin-left 250ms;
  overflow-y: auto;
  height: calc(100vh - #{$menu-height} - #{$action-row-height});

  @media only screen and (max-width: 639px) {
    height: calc(#{$mobile100vh} - #{$menu-height} - #{$action-row-height});
  }

  &--open {
    margin-left: 0;
  }

  &__container {
    padding: 15px 20px;
  }
}
