@import "../tools";

.action-row {
  display: flex;
  align-items: center;
  @include background(color("gray-800"), color("white"));
  padding: 0 20px;
  height: $action-row-height;
  flex-shrink: 0;
  transition: opacity 200ms;

  @media only screen and (max-width: 639px) {
    border-bottom: 1px solid;
    @include border-color(color("gray-900"), color("gray-200"));
  }

  * ~ * {
    margin-left: 12px;
  }

  &__button {
    // Reset button style
    font-family: inherit;
    text-align: left;
    -webkit-appearance: none;
    color: inherit;
    background: transparent;

    border: 1px solid;
    @include border-color(color("gray-500"), color("gray-700"));
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    opacity: 0.4 !important;
    line-height: 1.2;
    white-space: nowrap;

    &:focus-visible {
      outline: 2px solid;
      outline-offset: -2px;
      @include outline-color(color("white"), color("black"));
    }

    &--active {
      opacity: 1 !important;
    }
  }

  &__divider {
    flex-shrink: 0;
    width: 1px;
    @include background(color("gray-600"), color("gray-300"));
    height: 50%;
  }

  &__menu-trigger {
    // Reset button style
    font-family: inherit;
    font-size: inherit;
    text-align: left;
    -webkit-appearance: none;
    border: none;

    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    align-self: stretch;
    margin-right: 20px;
    @include color(color("gray-400"), color("black"));
    @include background(transparent, transparent);
    cursor: pointer;
    user-select: none;
    min-width: 10px;
    border-right: 1px solid transparent;
    transition: all 250ms;
    margin-left: -20px;
    padding-left: 20px;

    &:focus-visible {
      outline: 2px solid;
      outline-offset: -2px;
      @include outline-color(color("white"), color("black"));
    }

    &:hover {
      @include background(transparent, #ffcc7f);
    }

    &__icon {
      display: none;
      width: 20px;
      margin-right: 10px;

      @media screen and (max-width: 900px) {
        display: block;
        margin-right: 20px;
      }
    }

    &__label {
      margin-right: 24px;
      margin-left: 0;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }

    $openIndicator: null !default;

    &__open-indicator {
      $openIndicator: &;
      position: absolute;
      right: 0;
      transform: translateX(50%);
      border-radius: 2px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include background(color("gray-900"), color("white"));
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
      opacity: 0;
      transition: opacity 250ms;

      svg {
        @include color(color("gray-400"), color("gray-700"));
        width: 8px;
        margin-right: 3px;
      }
    }

    $open: null !default;

    &--open {
      $open: &;
      min-width: 295px;
      margin-right: 35px;
      @include border-color(color("gray-900"), color("gray-100"));
      @include background(color("gray-600"), #ffcc7f);

      #{$openIndicator} {
        opacity: 1;
      }
    }

    &:not(#{$open}) {
      @include background(transparent, #ffe2b7);

      &:hover {
        @include background(transparent, #ffcc7f);
      }
    }
  }
}
