@import "../../../tools";

.article-copy-to-clipboard-wrapper {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 639px) {
    display: none;
  }
}

.article-copy-to-clipboard {
  opacity: 0.5;

  &--active {
    opacity: 1;
  }
}
