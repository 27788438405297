@import "../../tools";

.text-input {
  position: relative;

  $input: null !default;
  $label: null !default;

  &__input {
    $input: &;
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: 1rem;
    border-radius: 2px;
    padding: 10px;
    margin: 0 0 1.07143rem;
    color: inherit;
    @include background(color("gray-900"), color("white"));
    border: 1px solid;
    @include border-color(color("gray-500"), color("gray-400"));

    &:focus {
      @include border-color(color("gray-200"), color("gray-500"));
    }
  }

  &__label {
    $label: &;
    position: absolute;
    top: 12px;
    left: 11px;
    font-size: 0.875rem;
    padding: 0;
    transition: all 250ms;
    @include color(color("gray-400"), color("gray-500"));
    pointer-events: none;
  }

  #{$input}:focus + #{$label},
  #{$input}:not(#{$input}--empty) + #{$label} {
    font-size: 0.8125rem;
    top: -8px;
    left: 7px;
    padding: 0 5px;
    @include background(color("gray-900"), color("white"));
    @include color(color("gray-200"), color("gray-700"));
  }
}
