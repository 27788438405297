@import "../../tools";

.request-new-event {
  &__trigger {
    // Reset button style
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    color: inherit;
    border: none;
    text-align: left;
    -webkit-appearance: none;

    @media only screen and (max-width: 639px) {
      font-size: 0;
    }

    &:focus-visible {
      outline: 2px solid;
      outline-offset: -2px;
      @include outline-color(color("white"), color("black"));
      border-radius: 2px;
    }
  }

  &__modal {
    &__header {
      font-family: "Overpass", sans-serif;
      font-size: 1.563rem;
      margin-bottom: 20px;
    }

    &__form {
      &__grid-50-50 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media only screen and (max-width: 639px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &__grid-gap-10 {
        grid-column-gap: 10px;
      }

      &__grid-gap-30 {
        grid-column-gap: 30px;
      }

      &__header {
        font-family: "Overpass", sans-serif;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 600;
        @include color(color("gray-400"), color("gray-500"));
        margin-top: 44px;
      }

      &__input {
        margin-top: 20px;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > * ~ * {
        margin-left: 10px;
      }
    }
  }
}
