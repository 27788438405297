@import "../global/config";
@import "../tools";

.item {
  // Reset button style
  font-family: inherit;
  border-top: none;
  border-right: none;
  border-bottom: none;
  text-align: left;
  -webkit-appearance: none;
  width: 100%;

  position: relative;
  padding: 4px 8px 8px;
  border-left: 3px solid transparent;
  cursor: pointer;
  @include background(color("gray-800"), color("white"));
  background-color: color("white");
  margin-bottom: 4px;
  @include color(color("gray-300"), color("black"));
  border-radius: 2px;

  &:focus-visible {
    outline: 2px solid;
    outline-offset: -2px;
    @include outline-color(color("white"), color("black"));
    border-radius: 2px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    display: inline-block;
    font-size: 0.85rem;
    letter-spacing: 0.22px;
    padding: 1px 4px;
    border-radius: 3px;
    margin-top: 8px;
    line-height: 1.3;
    @include background(color("cercise-100"), color("cercise-100"));
    @include color(color("cercise-500"), color("cercise-500"));

    &--tranquil {
      @include background("inherit", #e4f0f1);
      @include color("inherit", #3c5e61);
    }
  }

  $time: null !default;
  $multi-day-indicator: null !default;
  $icon: null !default;

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    height: 24px;

    &__time {
      $time: &;
      line-height: 1;
      font-size: 0.8125rem;
      margin-right: 8px;
    }

    &__label {
      margin-top: 0px;
      margin-left: 10px;
    }

    &__status {
      display: flex;
      align-items: center;

      &__text {
        padding: 2px 4px;
        border-radius: 3px;
        font-weight: 500;
        font-size: 0.8125rem;
        margin-right: 8px;
        margin-left: 8px;
        line-height: 1.1;
      }

      &--updated {
        .item__info__status__text {
          color: color("emerald-500");
          background-color: color("emerald-200");
        }
      }
    }

    &__icons {
      $icon: &;
      position: relative;
      display: flex;
      align-items: center;
      gap: 0.3rem;
      margin-left: auto;
      @include color(color("gray-300"), color("gray-700"));

      &__container {
        position: relative;
        display: flex;

        &--cancelled {
          @include color(color("gray-500"), color("gray-400"));
        }

        &__cancelled-line {
          position: absolute;
          top: 0;
          transform: rotate(-45deg) translate(-9px, 3px);
          background: color("red-500");
          height: 1px;
          width: 150%;
        }
      }
    }
  }

  &__multi-day-indicator {
    $multi-day-indicator: &;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    opacity: 0.15;
    width: 25px;
    @include color(color("white"), color("black"));
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &__title {
    font-size: 14px;
    line-height: 1.5;

    &--multi-day-item {
      padding-right: 25px;
    }
  }

  &__multi-day-indicator {
    $multi-day-indicator: &;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    opacity: 0.15;
    width: 25px;
    @include color(color("white"), color("black"));
  }

  &--priority-high {
    @include border-color(#7f1b1b, color("red-500"));
    @include color(color("white"), color("white"));
    @include background(#7f1b1b, color("red-500"));

    #{$multi-day-indicator} {
      @include color(color("black"), color("black"));
      opacity: 0.25;
    }

    #{$icon} {
      @include color(color("gray-200"), color("gray-200"));
    }
  }

  &--priority-medium {
    @include color(#ff9c9c, color("red-500"));
    @include background(color("gray-800"), color("white"));

    #{$time} {
      @include color(color("gray-300"), color("gray-800"));
    }

    #{$icon} {
      @include color(color("gray-300"), color("gray-700"));
    }
  }

  &--read {
    opacity: 0.5;
  }

  &--active {
    opacity: 1;
    border-left: 3px solid;
    @include border-color(color("orange-500"), color("orange-500"));
  }

  @media not print {
    &--with-inline-article-view {
      position: relative;
      margin-right: 800px !important;
    }
  }

  &__article-view-wrapper {
    position: absolute;
    left: 305px;
    padding-bottom: 8px;
    @include color(color("gray-300"), color("black"));

    & > div {
      margin: 0;
      cursor: initial;
    }
  }
}
