@import "../global/config";
@import "../tools";

.column {
  flex-grow: 0;
  min-width: 300px;
  max-width: 300px;
  @include background(color("gray-700"), color("gray-200"));
  margin: 0 5px;
  border-radius: 4px;
  height: 100%;
  min-height: 200px;

  @media only screen and (max-width: 639px) {
    min-width: calc(100vw - 10px);
    max-width: calc(100vw - 10px);
  }

  &__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: $column-headline-height;
    font-size: 14px;
    @include color(color("gray-300"), color("black"));

    &--draggable {
      cursor: grab;
    }

    &--dragging {
      cursor: grabbing;
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;

      @media only screen and (max-width: 639px) {
        width: 100%;
      }
    }

    &__select {
      $select: &;

      :global {
        #{$select} {
          &__control {
            min-width: 100px;
            min-height: auto;
            border: none;
            background-color: transparent;
            box-shadow: none;
          }

          &__menu {
            border-radius: 0;
          }

          &__menu-list {
            padding: 0;
          }

          &__option {
            text-transform: uppercase;
            font-size: 12px;
            @include background(color("gray-700"), color("white"));

            &--is-focused {
              @include background(color("gray-600"), color("gray-200"));
            }

            &--is-selected {
              @include background(color("gray-900"), color("gray-600"));
            }
          }

          &__value-container {
            justify-content: flex-end;
            padding: 0;
          }

          &__single-value {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            @include color(color("gray-400"), color("gray-600"));
          }

          &__indicator-separator {
            display: none;
          }

          &__dropdown-indicator {
            padding: 0;

            &__arrow {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 5px 0 5px;
              margin: auto 0 auto 4px;
              transition: transform 300ms;
              @include border-color(
                color("gray-400") transparent transparent transparent,
                color("gray-500") transparent transparent transparent
              );

              &--rotated {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
    }

    &__title {
      &--clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__scrollable {
    overflow: auto;
    height: calc(100% - #{$column-headline-height});
    padding: 0 10px 10px;

    &.column__scrollable--has-scrollbar {
      padding-right: 0;
    }

    &__no-results {
      opacity: 0.7;
      font-size: 0.9375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  &--inProgress {
    @include background("inherit", color("signal-yellow-400"));

    .loading {
      @include border-color("inherit", color("signal-yellow-400"));
    }
  }

  &--beingUpdated {
    @include background("inherit", color("emerald-300"));

    .loading {
      @include border-color("inherit", color("emerald-300"));
    }
  }

  &--published {
    @include background("inherit", color("emerald-500"));

    .column__headline {
      @include color(color("gray-300"), color("white"));

      &__select {
        @include color(color("gray-300"), color("black"));

        :global {
          .column__headline__select__single-value {
            @include color(color("gray-300"), color("white"));
          }

          .column__headline__select__dropdown-indicator__arrow {
            @include border-color(
              color("white") transparent transparent transparent,
              color("white") transparent transparent transparent
            );
          }
        }
      }
    }

    .loading {
      @include border-color("inherit", color("emerald-500"));
    }
  }

  &--tranquil {
    @include background("inherit", #e4f0f1);

    .loading {
      @include border-color("inherit", #e4f0f1);
    }
  }

  &--detailed {
    min-width: 450px;
    max-width: 450px;

    @media only screen and (max-width: 639px) {
      min-width: calc(100vw - 10px);
      max-width: calc(100vw - 10px);
    }

    .column__headline {
      .column__headline__inner {
        width: 100%;
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - #{$column-headline-height});
  //@include background(color("gray-800"), color("white"));
  @include border-color(color("gray-700"), color("gray-200"));
  border: 10px solid;
  border-top: none;
}
