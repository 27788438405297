@import "../tools";

.filter-menu {
  &__section {
    margin-bottom: 2.5rem;

    &__heading {
      font-family: "Overpass", sans-serif;
      font-size: 0.9rem;
      letter-spacing: 0.28px;
      font-weight: 600;
      margin-bottom: 1rem;

      &--uppercased {
        text-transform: uppercase;
      }
    }

    &__content {
      ul {
        list-style-type: none;
        padding: 0;
        border-top: 1px solid;
        @include border-color(color("gray-900"), color("gray-200"));
      }

      &__li {
        border-bottom: 1px solid;
        @include border-color(color("gray-900"), color("gray-200"));
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          max-width: 20px;
        }

        &__title {
          cursor: pointer;

          &--active {
            font-weight: 500;
          }
        }

        &--inactive {
          opacity: 0.6;

          span {
            cursor: initial !important;
          }
        }

        &__actions {
          display: flex;

          div {
            cursor: pointer;
            display: flex;

            &:first-of-type {
              margin-left: auto;
            }
          }

          svg {
            margin-left: 0.75rem;
            width: 16px;
            height: 16px;
          }
        }

        &:hover {
          :global {
            .section__content__li__actions {
              display: flex;
            }
          }
        }
      }

      &__warning {
        display: flex;
        align-items: flex-start;
        svg {
          max-width: 20px;
          opacity: 0.6;
          margin-right: 0.8rem;
          margin-top: 0.3rem;
        }

        span {
          font-size: 14px;
          font-style: italic;
        }
      }
    }
  }
}
