@import "../global/config";
@import "../tools";

.item {
  // Reset button style
  font-family: inherit;
  border-top: none;
  border-right: none;
  border-bottom: none;
  text-align: left;
  -webkit-appearance: none;
  width: 100%;

  position: relative;
  padding: 12px 8px 8px;
  border-left: 3px solid transparent;
  cursor: pointer;
  @include background(color("gray-800"), color("white"));
  background-color: color("white");
  margin-bottom: 10px;
  @include color(color("gray-300"), color("black"));
  border-radius: 2px;

  &:focus-visible {
    outline: 2px solid;
    outline-offset: -2px;
    @include outline-color(color("white"), color("black"));
    border-radius: 2px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  $icon: null !default;
  $calendar-info: null !default;
  $multi-day-indicator: null !default;

  &__multi-day-indicator {
    $multi-day-indicator: &;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    opacity: 0.15;
    width: 25px;
    @include color(color("white"), color("black"));
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    &__status {
      display: flex;
      align-items: center;
      margin-bottom: 0.45rem;

      &__text {
        padding: 3px 6px;
        border-radius: 3px;
        font-weight: 500;
        font-size: 14px;
        margin-right: 8px;
        margin-left: 8px;
        line-height: 1.1;
      }

      &--updated {
        .item__info__status__text {
          color: color("emerald-500");
          background-color: color("emerald-200");
        }
      }
    }

    &__label {
      font-size: 14px;
      letter-spacing: 0.22px;
      padding: 3px 6px;
      border-radius: 3px;
      margin-right: 6px;
      line-height: 1.3;
      margin-bottom: 0.45rem;
      @include background(color("cercise-100"), color("cercise-100"));
      @include color(color("cercise-500"), color("cercise-500"));

      &--tranquil {
        @include background("inherit", #e4f0f1);
        @include color("inherit", #3c5e61);
      }
    }

    &__icons {
      $icon: &;
      display: flex;
      align-items: center;
      margin-left: auto;
      gap: 0.3rem;
      @include color(color("gray-300"), color("gray-700"));

      &__container {
        position: relative;
        display: flex;

        &--cancelled {
          @include color(color("gray-500"), color("gray-400"));
        }

        &__cancelled-line {
          position: absolute;
          top: 0;
          transform: rotate(-45deg) translate(-9px, 3px);
          background: color("red-500");
          height: 1px;
          width: 150%;
        }
      }
    }
  }

  &__title {
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 1rem;

    &--multi-day-item {
      padding-right: 25px;
    }
  }

  &__content {
    p {
      font-size: 14px;
      margin: 0;
    }

    &--multi-day-item {
      p {
        padding-right: 25px;
      }
    }
  }

  :global {
    p {
      &.lead {
        font-size: 16px;
        margin-bottom: 1rem;
      }

      &.txt {
        word-break: break-word;
      }
    }

    table {
      &.newsCalInfo {
        display: none;
      }
    }
  }

  &__calendar-info {
    $calendar-info: &;
    border-collapse: collapse;
    font-size: 0.875rem;

    & > tbody > tr > td {
      padding-bottom: 8px;
    }

    td {
      vertical-align: top;

      &:first-child {
        font-weight: 500;
        text-transform: uppercase;
        padding-right: 8px;
      }

      a {
        color: inherit;
      }
    }
  }

  //&--priority-1 {
  //  border-color: color("red-500");
  //  @include color(color("white"), color("white"));
  //  background-color: color("red-500") !important;
  //}
  //
  //&--priority-3 {
  //  @include color(color("red-500"), color("red-500"));
  //  @include background(color("red-200"), color("white"));
  //
  //  #{$icon} {
  //    @include color(color("cercise-500"), color("cercise-500"));
  //  }
  //}

  &--priority-high {
    @include border-color(#7f1b1b, color("red-500"));
    @include color(color("white"), color("white"));
    @include background(#7f1b1b, color("red-500"));

    #{$multi-day-indicator} {
      @include color(color("black"), color("black"));
      opacity: 0.25;
    }

    #{$icon} {
      @include color(color("gray-200"), color("gray-200"));
    }
  }

  &--priority-medium {
    @include color(#ff9c9c, color("red-500"));
    @include background(color("gray-800"), color("white"));

    #{$calendar-info} {
      @include color(color("gray-300"), color("gray-800"));
    }

    #{$icon} {
      @include color(color("gray-300"), color("gray-700"));
    }
  }

  &--read {
    opacity: 0.5;
  }

  &--active {
    opacity: 1;
    border-left: 3px solid color("orange-500");
  }
}
