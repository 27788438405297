@import "../../tools";

$gutter-offset: -28px;

.slider {
  &--with-gutter-offset {
    margin-left: $gutter-offset;
    margin-right: $gutter-offset;

    @media only screen and (max-width: 639px) {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }

  &__item {
    padding: 0 3px;

    &--other-media {
      & > * * {
        max-height: 185px;
      }

      img {
        height: auto;
      }
    }
  }

  &__image-container {
    position: relative;

    &__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(#2d2c33, 0.75) 0%, rgba(0, 0, 0, 0) 40%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      cursor: pointer;

      &__caption {
        display: flex;
        align-items: center;
        padding: 20px 20px 18px;
        color: color("white");

        > svg {
          width: 19px;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
    }

    &__image {
      width: 100%;
      height: 246px;
      cursor: pointer;
      object-fit: contain;
      object-position: bottom;
      @include background(color("gray-700"), color("gray-200"));
    }
  }

  :global {
    .slick-slider {
      padding-bottom: 32px;
    }

    .slick-arrow {
      top: initial;
      bottom: 0;
      color: currentcolor;
    }

    .slick-prev {
      right: 35px - $gutter-offset;
      transform: rotate(180deg);
      left: initial;
    }

    .slick-next {
      transform: initial;
      right: 0px - $gutter-offset;
    }

    .slick-track {
      display: flex;

      > * {
        height: auto;
        max-width: 50%;

        * {
          //height: 100%;
        }
      }
    }
  }
}
