@import "../tools";

.settings {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  @media only screen and (max-width: 639px) {
    display: block;
  }

  &__aside {
    width: 300px;
    border-right: 1px solid;
    @include border-color(color("gray-800"), color("gray-200"));

    @media only screen and (max-width: 639px) {
      width: auto;
      border-right: unset;
    }

    &__nav {
      list-style: none;
      margin: 0;
      padding: 0;

      @media only screen and (max-width: 639px) {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border-bottom: 1px solid;
        @include border-color(color("gray-800"), color("gray-200"));

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__item {
        border-bottom: 1px solid;
        @include border-color(color("gray-800"), color("gray-200"));
        font-weight: 500;
        opacity: 0.8;

        @media only screen and (max-width: 639px) {
          flex: 0 0 auto;
          border-bottom: unset;
        }

        &--back {
          svg {
            transform: rotate(180deg);
            width: 17px;
            margin-right: 10px;
          }
        }

        &--active {
          opacity: 1;
          border-right: 3px solid color("orange-500") !important;

          @media only screen and (max-width: 639px) {
            border-right: unset !important;
            border-bottom: 3px solid color("orange-500") !important;
          }
        }

        > a {
          display: block;
          padding: 16px 18px;
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }

  &__content {
    flex-grow: 1;
    height: auto;
    overflow-y: scroll;
    padding-left: 1rem;
    padding-right: 1rem;

    @media only screen and (max-width: 639px) {
      height: calc(#{$mobile100vh} - #{$menu-height} - #{$action-row-height});
    }
  }
}
