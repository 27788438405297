@import "../../tools";

.overview {
  &__header {
    font-family: "Overpass", sans-serif;
    font-size: 1.563rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 10px;

    &__span-2 {
      grid-column: span 2;
    }
  }

  &__scope {
    &__title {
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 3px;
    }

    &__description {
      font-size: 14px;
      opacity: 0.8;
      margin-bottom: 20px;
      line-height: 1.5;
    }

    &__key-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 10px;
      margin-bottom: 20px;

      &--2-columns {
        grid-template-columns: repeat(2, 1fr);
      }

      &__container {
        &__description {
          opacity: 0.8;
          font-weight: 500;
          font-size: 13px;
          text-transform: uppercase;
          @include color(color("emerald-400"), color("emerald-500"));
        }

        &__key-combinations {
          margin-top: 12px;
          margin-bottom: 15px;

          &__combination {
            display: flex;
            margin-bottom: 10px;

            $key-depth-of-field: 2px;

            &__key {
              margin-right: 3px;
              @include background(color("gray-500"), color("white"));
              border: 1px solid;
              @include border-color(color("gray-600"), color("gray-300"));
              padding: 3px 6px;
              border-radius: 5px;
              font-size: 13px;
              font-weight: 500;
              @include box-shadow(
                0 $key-depth-of-field 0 0 color("gray-600"),
                0 $key-depth-of-field 0 0 color("gray-300")
              );
              text-transform: uppercase;
              min-width: 27px;
              text-align: center;
              cursor: initial;
              transform: translateY(0);
              transition: all 100ms;
              user-select: none;

              &:active {
                @include box-shadow(0 0 0 0 color("gray-600"), 0 0 0 0 color("gray-300"));
                transform: translateY($key-depth-of-field);
              }
            }
          }
        }
      }
    }
  }
}
