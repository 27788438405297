@import "../../tools";

$article-view-padding: 2.5rem;
$article-view-padding-mobile: 1rem;
$article-sidebar-width: 130px;

.article-view {
  flex-grow: 0;
  min-width: 790px;
  max-width: 790px;
  border: 1px solid;
  @include border-color(color("gray-600"), color("gray-400"));
  @include background(color("gray-800"), color("white"));
  margin: 0 5px;
  height: 100%;
  min-height: 200px;
  position: relative;

  @media only screen and (max-width: 639px) {
    min-width: auto;
    max-width: none;
    position: absolute;
    width: calc(100vw - 10px);
  }

  &__notification {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: $column-headline-height;
    @include background(color("gray-800"), color("white"));
    border-bottom: 1px solid;
    @include border-color(color("gray-600"), color("gray-400"));
    width: 100%;
    z-index: 2;

    &__text {
      margin: 0 10px;
      font-weight: 500;

      a {
        color: color("emerald-500");
        cursor: pointer;
      }

      @media only screen and (max-width: 639px) {
        font-size: 0.875rem;
      }
    }
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: $column-headline-height;
    border-bottom: 1px solid;
    @include border-color(color("gray-600"), color("gray-400"));

    &__datetime,
    &__info {
      font-size: 0.9rem;
      font-family: "Overpass", sans-serif;
    }

    &__info {
      margin-left: auto;
      margin-right: 20px;
    }

    &__actions {
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
        width: 100%;
        max-width: 17px;
        min-width: 17px;
        max-height: 17px;
        margin-right: 18px;
      }

      button {
        margin-right: 1rem;
        margin-left: 1rem;
      }

      &__download {
        display: flex;

        svg {
          display: none;
        }

        @media only screen and (max-width: 639px) {
          button {
            display: none;
          }

          svg {
            display: block;
          }
        }
      }

      &__close {
        max-width: 10px !important;
        min-width: 10px !important;
        margin-right: 0 !important;
      }
    }
  }

  &__scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - #{$column-headline-height});
    padding: $article-view-padding;
    padding-top: 1.5rem;

    @media only screen and (max-width: 639px) {
      padding: $article-view-padding-mobile;
    }
  }

  &--with-notification {
    .article-view__scrollable {
      height: calc(100% - #{$column-headline-height} - #{$column-headline-height});
    }
  }

  &__coverage-editorial-notes {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;

    &__item {
      display: flex;
      gap: 4px;
      font-size: 0.875rem;
      margin-bottom: 5px;

      &__icon {
        &--cancelled {
          position: relative;
          opacity: 0.7;
        }

        &__cancelled-line {
          position: absolute;
          top: 0;
          transform: rotate(-45deg) translate(-9px, 3px);
          background: color("red-500");
          height: 1px;
          width: 140%;
        }
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 639px) {
      display: block;
    }

    &__left {
      flex: 0 0 50%;
      width: 50%;
      margin-top: 2rem;

      @media only screen and (max-width: 639px) {
        width: 100%;
        margin-bottom: 1.5rem;
        margin-top: 0;
      }

      &__title {
        font-size: 1.6rem;
        font-family: "Overpass", sans-serif;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;

        @media only screen and (max-width: 639px) {
          margin-right: 0;
          font-size: 1.4rem;
          margin-bottom: 0.75rem;
        }
      }

      &__editorial-info {
        border-left: 4px solid color("orange-500");
        padding: 10px;
        padding-right: 1.5rem;
        text-align: left;
        font-size: 0.875rem;

        @media only screen and (max-width: 639px) {
          margin-bottom: 1rem;
        }
      }

      &__article-details {
        display: none;

        @media only screen and (max-width: 639px) {
          display: flex;
        }

        ul {
          border-top: 1px solid;
          @include border-color(color("gray-500"), color("gray-500"));
          padding-left: 0;
          list-style-type: none;
          margin-left: 1rem;
          margin-top: 1rem;
          padding-top: 0.75rem;
          min-width: 100px;

          &:first-of-type {
            margin-left: 0;
          }
        }

        &__byline {
          font-weight: 500;
        }

        &__version {
          color: color("emerald-500");
        }
      }
    }

    &__right {
      flex: 0 0 50%;
      width: 50%;

      @media only screen and (max-width: 639px) {
        width: 100%;
      }

      img {
        width: 100%;
        cursor: pointer;
        display: block;

        @media only screen and (max-width: 639px) {
          max-height: 246px;
          object-fit: contain;
          @include background(color("gray-700"), color("gray-200"));
        }
      }
    }

    &--no-media {
      .article-view__header__left {
        flex: 0 0 100%;
        width: 100%;
        margin-top: 0;

        &__title {
          margin-right: 0;
        }
      }
    }
  }

  &__main-content {
    display: flex;

    @media only screen and (max-width: 639px) {
      display: block;
    }

    &__article {
      flex-grow: 1;

      &__lead {
        font-family: "Overpass", sans-serif;
        font-size: 1.25rem;
        line-height: 1.5;
        margin-bottom: 35px;
      }

      &__body {
        line-height: 1.5;
        word-break: break-word;

        :global {
          .lead {
            display: none;
          }

          .hl2 {
            font-weight: 700;
            margin-top: 25px;
          }

          a {
            color: color("orange-500");
          }

          table {
            &.newsCalInfo {
              display: none;
            }
          }
        }
      }

      &__lead,
      &__body {
        :global {
          .highlight {
            background: #ffe2b7;
            @include color(#000, #000);
          }
        }
      }

      &__calendar-info {
        border-collapse: collapse;
        font-size: 0.875rem;

        & > tbody > tr > td {
          padding-bottom: 8px;

          &:first-child {
            font-weight: 500;
            text-transform: uppercase;
            padding-right: 8px;
          }
        }

        td {
          vertical-align: top;

          a {
            color: color("orange-500");
          }
        }

        table {
          border-spacing: 0;
        }
      }

      img {
        width: 100%;
        cursor: pointer;
        display: block;
      }
    }

    &__article-details {
      flex: 0 0 $article-sidebar-width;
      text-align: right;
      font-size: 0.875rem;
      margin-left: 25px;
      margin-top: 12px;
      width: $article-sidebar-width;

      @media only screen and (max-width: 639px) {
        display: none;
      }

      & > ul {
        list-style-type: none;
        padding-left: 0;

        & > li {
          border-top: 1px solid;
          padding-top: 8px;
          padding-bottom: 14px;
          @include border-color(color("gray-500"), color("gray-500"));
        }
      }

      &__byline {
        font-weight: 500;
      }

      &__version {
        color: color("emerald-500");
      }
    }
  }

  &__extra {
    display: grid;

    &--1-column {
      grid-template-columns: 1fr;
    }

    &--2-column {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;

      @media only screen and (max-width: 639px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__title {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
      font-family: "Overpass", sans-serif;
      font-weight: 600;
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    &__list {
      list-style-type: none;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      &__item {
        font-size: 0.875rem;

        &__link {
          color: inherit;
          text-decoration: 0;
          display: inline-block;
          margin-bottom: 1rem;
          cursor: pointer;

          &:after {
            content: "";
            padding-top: 0.2rem;
            display: block;
            border-bottom: 2px solid;
            @include border-color(color("emerald-500"), color("emerald-500"));
            transition: all 250ms ease-in-out;
            width: 75px;
          }

          &:hover {
            &:after {
              width: 100%;
            }
          }

          &__timestamp {
            opacity: 0.8;
          }
        }
      }
    }

    &__timeline {
      list-style-type: none;
      padding-left: 0;
      position: relative;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 9px;
        border: 1px solid;
        @include border-color(color("gray-600"), color("gray-300"));
        display: block;
        content: "";
      }

      &__item {
        font-size: 0.875rem;
        cursor: pointer;
        margin-top: 17px;
        padding-left: 30px;
        position: relative;

        & > span {
          opacity: 0.6;
        }

        $circle: null !default;

        &__circle {
          $circle: &;
          border: 2px solid;
          opacity: 1 !important;
          @include border-color(color("gray-600"), color("gray-300"));
          border-radius: 50%;
          width: 20px;
          height: 20px;
          line-height: 20px;
          background-color: #fff;
          z-index: 2;
          display: inline-block;
          margin-right: 0.5rem;
          position: absolute;
          left: 0;
          top: 0;
        }

        &__date-time {
          margin-right: 8px;
        }

        &__tag {
          color: color("emerald-500");
          opacity: 1 !important;
          text-transform: uppercase;
          margin-right: 0.5rem;
          background-color: color("emerald-200");
          padding: 4px 7px;
          border-radius: 3px;
          font-weight: 500;
        }

        &__title {
          font-weight: 500;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            left: 0;
            width: 30px;
            @include background(color("gray-800"), color("white"));
          }
        }

        &--current {
          & > span {
            opacity: 1;
          }

          #{$circle} {
            @include border-color(color("emerald-500"), color("emerald-500"));

            &:after {
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              width: 10px;
              height: 10px;
              @include background(color("emerald-500"), color("emerald-500"));
              display: block;
            }
          }
        }
      }
    }

    &__limit-switcher {
      font-size: 0.875rem;
      cursor: pointer;
      margin-top: 3px;
      font-weight: 500;
      color: color("emerald-500");
      position: relative;

      svg {
        position: absolute;
        left: 0;
        width: 28px;
        height: 28px;
        margin-left: -4px;
        top: -5px;
        color: color("gray-500");
      }

      &--with-icon {
        padding-left: 30px;
        margin-top: 17px;
      }
    }

    &__meta-tags {
      list-style-type: none;
      padding-left: 0;
      margin-top: 0;

      &__item {
        margin: 8px 12px 8px 0;
        cursor: pointer;

        & > span:first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__contact {
      margin-top: 2.5rem;
      padding: 15px 20px;
      border-left: 5px solid;
      font-size: 0.875rem;
      @include border-color(color("gray-700"), color("gray-600"));

      &__byline {
        font-weight: 500;
        font-size: 1rem;
      }

      &__writer-email {
        font-weight: 500;
        margin-top: 10px;

        a {
          color: inherit;
        }
      }
    }

    &__coverages {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      &__item {
        border: 1px solid;
        @include border-color(color("gray-500"), color("gray-300"));
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
      }
    }
  }
}
