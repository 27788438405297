@import "../../global/config";
@import "../../tools";

.news-item-detailed-skeleton {
  padding: 12px 8px 8px;
  border-left: 3px solid transparent;
  cursor: pointer;
  @include background(color("gray-800"), color("white"));
  background-color: color("white");
  margin-bottom: 10px;
  @include color(color("gray-300"), color("black"));

  &:last-child {
    margin-bottom: 0;
  }

  &--content {
    padding: 0;
    border-left: none;
  }
}
