@import "../../tools";

.calendar-view-mode {
  display: flex;

  &__item {
    @include color(color("gray-500"), color("gray-400"));
    cursor: pointer;
    pointer-events: all;

    &:hover {
      @include color(color("gray-400"), color("gray-500"));
      transition: color 150ms;
    }

    &--active {
      @include color(color("gray-300"), color("gray-700"));
      transition: color 150ms;

      &:hover {
        @include color(color("gray-300"), color("gray-700"));
      }
    }

    > svg {
      display: block;
      width: 25px;
      height: 25px;
      margin-right: 4px;
      margin-left: 4px;
    }

    &:first-child {
      svg {
        height: 23px !important;
      }
    }
  }
}
