@import "../../tools";

.radio {
  $radio-size: 17px;
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    content: "";
    position: absolute;
    border: 2px solid color("gray-200");
    width: $radio-size;
    height: $radio-size;
    top: 1px;
    border-radius: 50%;
    transition: all 200ms;
  }

  &::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    top: 6px;
    left: 5px;
    border-radius: 50%;
    transform: scale(0);
    transition: all 200ms;
  }

  $label: null !default;
  &__label {
    $label: &;
    padding-left: $radio-size * 1.7;
    font-weight: 400;
    font-size: 0.9375rem;
    opacity: 0.75;
  }

  &--checked {
    &::before {
      border-color: color("emerald-500");
    }

    &::after {
      background: color("emerald-500");
      transform: scale(1);
    }

    #{$label} {
      opacity: 1;
    }
  }
}
