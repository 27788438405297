@import "../../tools";

.primary-menu {
  margin-right: auto;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    display: flex;
  }

  &__anchor {
    text-decoration: none;
    display: flex;
    transition: color 0.15s;
    @include color(color("gray-300"), color("gray-900"));
    padding: 1.313rem 0.438rem calc(1.313rem - 5px);
    margin: 0 0.875rem;
    border-bottom: 4px solid transparent;
    position: relative;
    font-size: 1rem;
    height: $menu-height;
    align-items: center;
    opacity: 0.5;

    @media only screen and (max-width: 639px) {
      height: auto;
      margin: 0 0.1rem;
      font-size: 0.95rem;
      padding-top: 1rem;
      padding-bottom: 0.75rem;
    }

    &:not(&--is-active):hover {
      @include border-color(color("gray-600"), color("gray-200"));
      @include color(color("white"), color("black"));
    }

    &:focus-visible {
      outline: 2px solid;
      outline-offset: -2px;
      border-radius: 5px;
      @include outline-color(color("white"), color("black"));
    }

    &--is-active {
      border-color: color("orange-500");
      opacity: 1;
    }

    &--is-parent {
      opacity: 1;
    }
  }
}
