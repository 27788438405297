@import "../../tools";

:global {
  .react-datepicker {
    font-size: 0.875rem;
    color: inherit;
    border: none;
    background: transparent;

    &__year-dropdown,
    &__month-dropdown,
    &__month-year-dropdown {
      @include background(color("gray-700"), color("white"));
      @include border-color(color("gray-600"), color("gray-200"));
    }

    &__day,
    &__day-name,
    &__time-name {
      color: inherit;
    }

    &-popper {
      z-index: 102;
    }

    &__navigation {
      top: 6px;
    }

    &__header {
      background: transparent;
      @include border-color(color("gray-600"), color("gray-200"));

      .react-datepicker__current-month {
        font-family: "Overpass", sans-serif;
        font-size: 15px;
        font-weight: 600;
        color: inherit;

        &--hasYearDropdown,
        &--hasMonthDropdown {
          display: none;
        }
      }
    }

    &__day,
    &__month-text,
    &__quarter-text,
    &__year-text {
      color: inherit;

      &:hover {
        border-radius: 0;
        @include background(color("gray-500"), color("gray-300"));
      }

      &--disabled {
        @include color(rgba(color("gray-300"), 0.5), rgba(color("gray-800"), 0.4));
      }
    }

    &__day {
      &--in-selecting-range:not(&--in-range) {
        background-color: color("emerald-400");
        color: color("white");
      }
    }

    &__day--today {
      font-weight: 700;
      background-color: transparent;
      outline: 1px solid color("emerald-400");
    }

    &__day--in-selecting-range,
    &__day--selected,
    &__day--in-range {
      background-color: color("emerald-500");
      border-radius: 0;
      color: color("white");

      &:hover {
        background-color: color("emerald-500");
      }
    }

    &__day--keyboard-selected {
      background-color: color("emerald-500");
      border: none;
      border-radius: 0;
      &:hover {
        background-color: color("emerald-500");
      }
    }

    &__month-container:not(:first-of-type) {
      .react-datepicker__current-month {
        &--hasYearDropdown,
        &--hasMonthDropdown {
          display: block;
          margin-bottom: 2px;
        }
      }
    }

    &__year-option,
    &__month-option,
    &__month-year-option {
      line-height: 24px;

      &:hover {
        @include background(color("gray-800"), color("gray-300"));
      }
    }

    &__current-month {
      color: inherit;

      &--hasYearDropdown,
      &--hasMonthDropdown {
        + .react-datepicker__header__dropdown {
          font-family: "Overpass", sans-serif;
          font-size: 15px;
          font-weight: 600;

          & > div {
            margin-right: 30px;

            span[class*="--down-arrow"] {
              top: 4px;
            }
          }
        }
      }
    }

    &__portal {
      width: 100%;
      .react-datepicker {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    select {
      height: 2.2rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      font-size: inherit;
    }

    &__input-wrapper {
      display: flex;
      flex-direction: row;
      margin-right: 20px;
      position: relative;
      font-family: "Overpass", sans-serif;
    }

    &__input-field {
      border: none;
      border-bottom: color("gray-500") 1px solid;
      background: transparent;
      font-family: "Overpass", sans-serif;
      font-size: 24px;
      padding-bottom: 7px;
      padding-right: 10px;

      &.day {
        max-width: 75px;
      }

      &.month {
        max-width: 148px;
        cursor: pointer;
      }

      &.year {
        max-width: 75px;
      }
    }

    &__arrow {
      border: solid color("gray-500");
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;

      &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }

      &.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      &.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    &__input-icon {
      position: absolute;
      right: 0;
      top: 6px;
      font-size: 15px;
      cursor: pointer;
    }

    &__dropdown-wrapper {
      position: absolute;
      left: 0;
      width: 100%;
      top: 48px;
      background: color("white");
      text-align: left;
      box-shadow: 0 3px 6px #00000029;
      color: color("gray-500");
      font-size: 14px;
      line-height: 16px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      padding-top: 12px;
      overflow-y: scroll;
      max-height: 345px;
    }

    &__dropdown-option {
      position: relative;
      text-align: left;
      padding-left: 10px;
      margin-bottom: 12px;
      cursor: pointer;

      &.selected {
        color: #020202f2;
      }
    }
  }
}

.datepicker-range {
  width: fit-content;
  position: relative;
  margin-top: 10px;

  &__inputs {
    display: inline-flex;
    align-items: center;
    padding-right: 30px;
    height: 42px;
    border: 1px solid;
    border-radius: 3px;
    @include border-color(color("gray-500"), color("gray-400"));

    &--block {
      display: flex;
      width: 100%;
    }

    input {
      cursor: pointer;
      border: none;
      margin-bottom: 0;
      max-width: 120px;

      @media only screen and (max-width: 639px) {
        min-width: 90px;
        font-size: 0.9rem;
        padding-right: 0;
      }

      &:not(.empty) {
        opacity: 1;
        background: transparent !important;
      }
    }
  }

  &__select-options {
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 0;
    padding: 1px;

    svg {
      height: 0.35rem;
      transform: rotate(180deg);
    }

    a {
      font-size: 14px;
    }

    [data-tippy-root] {
      min-width: 12.5em;
    }
  }

  &__popover-container {
    min-width: max-content;
  }
}
