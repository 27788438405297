@import "../../tools";

.calendar-content-filters {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  &__item {
    // Reset button style
    font-family: inherit;
    text-align: left;
    -webkit-appearance: none;
    font-size: inherit;
    border: none;
    background: transparent;
    padding: 0;

    display: flex;
    @include color(color("gray-500"), color("gray-400"));
    cursor: pointer;
    pointer-events: all;

    &:focus-visible {
      outline: 2px solid;
      outline-offset: -2px;
      @include outline-color(color("white"), color("black"));
      border-radius: 2px;
    }

    &:hover {
      @include color(color("gray-400"), color("gray-500"));
      transition: color 150ms;
    }

    &--active {
      @include color(color("gray-300"), color("gray-700"));
      transition: color 150ms;

      &:hover {
        @include color(color("gray-300"), color("gray-700"));
      }
    }
  }
}
