.send-to-email {
  &__modal {
    &__header {
      font-family: "Overpass", sans-serif;
      font-size: 1.563rem;
      margin-bottom: 20px;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}
