@import "../../tools";

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#b0b6b9, 0.5);
  @include background(rgba(color("gray-900"), 0.65), rgba(color("gray-400"), 0.5));
  animation: fadeIn 350ms;
  overflow: hidden;

  :global {
    .article-view {
      width: auto;
      overflow: visible;
      height: 90%;
      padding: 10px 5px;
      max-width: initial;

      @media only screen and (max-width: 639px) {
        & > div {
          position: relative;
        }
      }
    }
  }
}

.modal {
  position: relative;
  max-height: 90%;
  overflow: auto;
  background: #fff;
  @include background(color("gray-700"), color("white"));
  border: 1px solid;
  @include border-color(color("gray-800"), color("gray-300"));
  width: 100%;
  max-width: 750px;
  @include box-shadow(rgba(color("black"), 0.7), 0 1px 6px -4px color("black"));
  //padding: 20px 15px;
  padding: 26px 45px;
  border-radius: 5px;
  animation: slideIn 300ms;

  &--small {
    max-width: 500px;
  }

  &--large {
    max-width: 80%;
  }

  &__close-button {
    position: absolute;
    top: 6px;
    right: 9px;
    width: 30px;
    padding: 8px;
    cursor: pointer;
    opacity: 0.5;
    transition: 150ms;

    &:hover {
      opacity: 1;
    }

    svg {
      pointer-events: none;
      display: block;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translatey(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
