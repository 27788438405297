@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap");
@import "../global/config";
@import "../tools";

* {
  box-sizing: border-box;

  &::before,
  &::after {
    @extend *;
  }

  &:focus {
    outline: none;
  }
}

html {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: color("gray-700");
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  min-height: 100%;
  @include background(color("gray-900"), color("white"));

  &.dark-theme {
    background: color("gray-900");
    color: color("gray-300");
  }

  &.light-theme {
    background: color("white");
  }

  &:not(.light-theme):not(.dark-theme) {
    background: color("white");

    @media (prefers-color-scheme: dark) {
      background: color("gray-900");
      color: color("gray-300");
    }
  }
}

strong {
  font-weight: 500;
}

#root {
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.highlight {
  background: #ffe2b7;
  @include color(#000, #000);
}
