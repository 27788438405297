@import "../../tools";

.caption {
  font-size: 0.875rem;
  padding: 5px 0;
  line-height: 1.5;
  user-select: text;
  max-width: fit-content;

  :global {
    .react-read-more-read-less {
      user-select: none;
      color: color("emerald-500");
      font-weight: 500;
    }
  }
}
