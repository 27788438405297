@import "../../tools";

.filter-form {
  &__modal {
    overflow: initial !important;

    &__menu__link {
      display: flex;
      cursor: pointer;
      svg {
        margin-right: 7px;
      }
    }

    &__header {
      font-family: "Overpass", sans-serif;
      font-size: 1.563rem;
      margin-bottom: 20px;
    }

    &__form {
      &__header {
        font-family: "Overpass", sans-serif;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 600;
        @include color(color("gray-400"), color("gray-500"));
        margin-top: 44px;
      }

      &__input {
        margin-bottom: 20px;

        &__error {
          color: color("red-500");
          margin: -0.7rem 0 1.5rem;
          padding: 0 0.6875rem;
          font-size: 0.75rem;
          line-height: 1.333;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      column-gap: 10px;

      &__delete {
        // Button reset start
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        /* inherit font & color from ancestor */
        color: inherit;
        font: inherit;
        /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
        line-height: normal;
        /* Corrects font smoothing for webkit */
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        /* Corrects inability to style clickable `input` types in iOS */
        -webkit-appearance: none;
        cursor: pointer;
        // Button reset end

        margin-right: auto;
        font-size: 14px;
        @include color(color("red-500"), color("red-500"));
      }

      &__counter {
        font-size: 0.875rem;
        margin-right: 16px;
      }
    }
  }
}
