.header-meta {
  display: flex;
  margin-left: 130px;
  font-family: "Overpass", sans-serif;
  font-size: 22px;
  font-weight: 400;

  @media only screen and (max-width: 639px) {
    margin-left: auto;
  }

  &__main,
  &__secondary {
    font-size: inherit;
    font-weight: inherit;
  }

  &__main {
    opacity: 0.5;
  }

  &__secondary {
    margin-left: 18px;

    @media only screen and (max-width: 639px) {
      display: none;
    }
  }
}
