@import "../global/config";

$dashboard-padding: 8px;

.dashboard {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100vh - #{$menu-height + $action-row-height + ($dashboard-padding * 2)});
  margin: $dashboard-padding 0;

  @media only screen and (max-width: 639px) {
    position: relative;
    height: calc(#{$mobile100vh} - #{$menu-height + $action-row-height + ($dashboard-padding * 2)});
  }
}

.reorder-item {
  display: flex;
}
