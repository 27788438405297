@import "../tools";

.header {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  height: 78px;
  @include background(color("gray-800"), color("white"));
  border-bottom: 1px solid;
  @include border-color(color("gray-900"), color("gray-200"));
  position: relative;
  z-index: 2;
  flex-shrink: 0;

  &__logo {
    margin-right: auto;
    color: inherit;

    &:focus-visible {
      outline: 2px solid;
      outline-offset: 2px;
      border-radius: 3px;
      @include outline-color(color("white"), color("black"));
    }

    @media screen and (max-width: 680px) {
      display: none;
    }

    @media screen and (min-width: 1600px) {
      margin-right: 100px;
    }

    > svg {
      display: flex;
      width: 75px;
      margin-top: 4px;
    }
  }

  &__search-field {
    margin: 0 15px;

    @media screen and (max-width: 680px) {
      margin-left: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__user-menu {
      margin-left: 45px;

      @media screen and (max-width: 900px) {
        margin-left: 0;
      }
    }
  }

  &__hamburger-menu {
    margin-right: 15px;
    cursor: pointer;
    span {
      @include background(color("gray-200") !important, color("gray-700") !important);
    }
  }
}
