@import "../../tools";

.select {
  :global {
    .select {
      &__control {
        border-radius: 3px;
        @include background(color("gray-900"), color("white"));
        @include border-color(color("gray-500"), color("gray-400"));

        &--is-focused {
          box-shadow: none;
          @include border-color(color("gray-500"), color("gray-600"));
        }
      }

      &__multi-value {
        @include background(color("gray-800"), color("gray-800"));

        &__label,
        &__remove {
          @include color(color("gray-100"), color("gray-100"));
        }
      }
    }
  }
}
