@import "../tools";

.pulser {
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 var(--pulser-0);
  animation: pulser 2s infinite;
  background-color: var(--pulser);

  &--yellow {
    --pulser: #{color("signal-yellow-500")};
    --pulser-0: #{rgba(color("signal-yellow-500"), 0.4)};
  }

  &--green {
    --pulser: #{color("emerald-500")};
    --pulser-0: #{rgba(color("emerald-500"), 0.4)};
  }

  @-webkit-keyframes pulser {
    0% {
      -webkit-box-shadow: 0 0 0 0 var(--pulser-0);
    }
    70% {
      -webkit-box-shadow: 0 0 0 7px transparent;
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 transparent;
    }
  }

  @keyframes pulser {
    0% {
      -moz-box-shadow: 0 0 0 0 var(--pulser-0);
      box-shadow: 0 0 0 0 var(--pulser-0);
    }
    70% {
      -moz-box-shadow: 0 0 0 7px transparent;
      box-shadow: 0 0 0 7px transparent;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 transparent;
      box-shadow: 0 0 0 0 transparent;
    }
  }
}
