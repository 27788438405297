@import "../../tools";

.notification-center {
  position: relative;

  &__count {
    position: absolute;
    top: -4px;
    right: -13px;
    padding: 2px 7px;
    background: color("emerald-500");
    color: color("white");
    font-size: 0.75rem;
    pointer-events: none;
  }

  &__content {
    width: 600px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include background(color("gray-900"), color("gray-100"));
      padding: 20px 20px 20px 30px;

      &__title {
        font-family: "Overpass", sans-serif;
        font-size: 1.563rem;
      }
    }

    &__groups {
      max-height: 60vh;
      overflow-y: auto;

      &__group {
        margin-bottom: 8px;

        &__header {
          padding: 10px 30px;
          font-size: 0.875rem;
          color: color("gray-500");
          border-bottom: 1px solid;
          @include border-color(color("gray-800"), color("gray-200"));
        }

        &__item {
          padding: 15px 30px;
          transition: background 250ms;

          &:not(:last-child) {
            border-bottom: 1px solid;
            @include border-color(color("gray-800"), color("gray-200"));
          }

          &:hover {
            @include background(color("gray-600"), color("gray-100"));
          }

          $item-content: null !default;
          &__content {
            $item-content: &;

            padding: 5px 0 5px 15px;

            &__title {
              font-weight: 500;
            }
          }

          &--priority-1 {
            #{$item-content} {
              border-left: 3px solid color("red-500");
            }
          }

          &--priority-2 {
            #{$item-content} {
              border-left: 3px solid color("red-500");
              color: color("red-500");
            }
          }

          &--read {
            #{$item-content} {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
