@import "../../../tools";

.article-print-wrapper {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 639px) {
    display: none;
  }
}

.article-print {
  max-width: 21px !important;
  max-height: 22px !important;
}
