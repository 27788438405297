@import "../tools";

.Toastify__toast-container .Toastify__toast {
  border-radius: 3px;
  @include color(color("gray-700"), color("gray-900"));
  padding: 0.75rem;
  min-height: 0;
  border: 1px solid;
  @include border-color(color("gray-200"), color("gray-600"));
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);

  //&.Toastify__toast--error {
  //  background: lighten(get-color(alert), 50%);
  //  border: 1px solid get-color(alert);
  //}
  //
  //&.Toastify__toast--success {
  //  background: lighten(get-color(success), 42%);
  //  border: 1px solid get-color(success);
  //}

  //.Toastify__toast-body {
  //  margin: 0;
  //  line-height: 1rem;
  //  font-size: 0.9rem;
  //  font-weight: bold;
  //  display: flex;
  //  align-content: center;
  //  align-items: center;
  //}

  //.fas {
  //  font-size: 1.5rem;
  //  margin-right: 0.5rem;
  //}

  .close {
    font-size: 28px;
    line-height: 20px;
    font-weight: 100;
  }
}
//}
