@import "../tools";

$calendar-padding: 8px;

.calendar-container {
  width: 100%;
  transition: width 250ms;

  &__action-row {
    padding: 0 5px;
    height: $calendar-action-row-height;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    @include background(color("gray-800"), color("white"));

    &__filters,
    &__action-links,
    &__view-mode,
    &__date {
      display: flex;
      align-items: center;
      height: 42px;
      margin-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &__date {
      span {
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    &__filters {
      border: 1px solid;
      border-radius: 3px;
      @include border-color(color("gray-500"), color("gray-400"));
      margin-left: 18px;
      margin-right: 3rem;
    }

    &__action-links {
      margin-left: auto;

      & > div,
      & > button {
        display: flex;
        cursor: pointer;
        color: color("emerald-500");

        svg {
          width: 20px;
          height: 20px;
          margin-right: 7px;
          margin-left: 7px;
        }
      }
    }

    &__view-mode {
      margin-left: 3rem;
      margin-right: 1rem;

      @media only screen and (max-width: 639px) {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }
    }
  }

  &__calendar {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    height: calc(
      100vh - #{$menu-height + $action-row-height + $calendar-action-row-height +
        ($calendar-padding * 2)}
    );
    margin: $calendar-padding 0;

    @media only screen and (max-width: 639px) {
      position: relative;
      height: calc(
        #{$mobile100vh} - #{$menu-height + $action-row-height + $calendar-action-row-height + (
            $calendar-padding * 2
          )}
      );
    }
  }
}
