@import "../../tools";

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main {
  flex-grow: 1;
  max-width: 1175px;
  width: 100%;
  margin: 50px auto;

  .section ~ .section {
    margin-top: 40px;
    padding-top: 25px;
  }
}

.section {
  &__title {
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 12px;
  }

  &__setting {
    margin-top: 15px;

    &--radio {
      .inline-label {
        margin-bottom: 5px;
      }
    }
  }
}

.inline-label {
  display: inline-block;
  font-weight: 500;
  margin-right: 0.438rem;
  cursor: inherit;
}

.filter-search {
  margin-top: 60px;
  margin-bottom: 16px;
  width: min(100%, 350px);
}

.notifications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid;
  @include border-color(color("gray-700"), color("gray-300"));

  &:first-of-type {
    border-top: 1px solid;
    @include border-color(color("gray-700"), color("gray-300"));
  }

  &__name {
    font-size: 0.938rem;
    font-weight: 500;
  }

  &__options {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 1;

    > * {
      margin-left: 50px;
      flex-shrink: 0 !important;
      flex-grow: 0 !important;
      flex-wrap: nowrap !important;

      * {
        white-space: nowrap;
      }
    }
  }
}
