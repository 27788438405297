@import "../../tools";

.label {
  display: inline-flex;
  position: relative;
  @include color(color("gray-300"), color("gray-700"));
  @include background(color("gray-700"), color("gray-200"));
  padding: 6px 15px;
  border-radius: 3px;
  text-decoration: none;

  &:not(:not(button)) {
    border: none;
    font-size: initial;
    line-height: inherit;
  }
}
