@use "sass:math";
@import "../../tools";

.main-menu {
  display: none;
  position: fixed;
  background: rgba(color("black"), 0.3);
  z-index: 2;
  top: $menu-height;
  width: 100%;
  height: calc(100vh - #{$menu-height});

  @media only screen and (max-width: 639px) {
    height: calc(#{$mobile100vh} - #{$menu-height});
  }

  &--open {
    display: flex;
  }

  $menu-main-header: null !default;
  $menu-main-list: null !default;
  $menu-main-list-item: null !default;
  $menu-main-list-item--active: null !default;

  &__main {
    display: flex;
    flex-direction: column;
    width: 25%;
    @include background(color("gray-700"), color("white"));
    border-right: 1px solid;
    @include border-color(color("gray-900"), color("gray-200"));
    z-index: 2;

    @media only screen and (max-width: 1199px) {
      width: math.div(100, 3) * 1%;
    }

    @media only screen and (max-width: 1023px) {
      width: 50%;
    }

    @media only screen and (max-width: 639px) {
      display: none;
      width: 100%;
    }

    &--active-mobile {
      display: flex;
    }

    &__header {
      $menu-main-header: &;
      font-family: "Overpass", sans-serif;
      letter-spacing: 1.5px;
      font-weight: 400;
      text-transform: uppercase;
      padding: 0 79px;
      margin-top: 32px;

      @media only screen and (max-width: 639px) {
        padding: 0 24px;
      }

      @media only screen and (max-height: 610px) {
        margin-top: 15px;
      }
    }

    &__list {
      $menu-main-list: &;
      flex-grow: 1;
      padding: 0;
      margin: 10px 0 0;

      &__item {
        $menu-main-list-item: &;
        display: flex;
        padding: 11px 52px;
        font-size: 1.313rem;
        font-weight: 300;

        &::before {
          content: "";
          height: 11px;
          width: 11px;
          border-radius: 50%;
          background: color("orange-500");
          margin: auto 16px auto 0;
          opacity: 0;
          transition: opacity 500ms ease;
        }

        @media only screen and (max-width: 639px) {
          padding: 8px 24px;
          font-size: 1.063rem;

          &::before {
            display: none;
          }
        }

        @media only screen and (max-height: 610px) {
          padding: 6px 24px;
        }

        > a {
          color: inherit;
          text-decoration: none;
        }

        &__navigation {
          margin-left: auto;
          @include background(color("gray-400"), color("gray-700"));
          @include color(color("gray-700"), color("gray-400"));
          height: 35px;
          width: 35px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          @media only screen and (max-height: 610px) {
            height: 25px;
            width: 25px;
          }

          & > svg {
            width: 20px;

            @media only screen and (max-height: 610px) {
              width: 15px;
            }
          }
        }

        &--active {
          $menu-main-list-item--active: &;
          font-weight: 400;

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &__sub-menu {
      list-style: none;
      padding: 17px 0;
      margin: 0 0 32px;
      border-left: 8px solid;
      @include border-color(color("gray-400"), color("gray-700"));

      &__item {
        font-size: 1.188rem;
        font-weight: 300;
        padding: 2px 64px 2px;

        @media only screen and (max-width: 639px) {
          padding-left: 15px;
        }

        > a {
          color: inherit;
          text-decoration: none;

          &:hover {
            font-weight: 400;
          }
        }
      }
    }
  }

  &__secondary {
    width: 25%;
    z-index: 1;
    @include background(color("gray-700"), color("white"));
    border-right: 1px solid;
    @include border-color(color("gray-900"), color("gray-200"));
    margin-left: -50%;
    transition: margin-left 800ms ease;

    @media only screen and (max-width: 1199px) {
      width: math.div(100, 3) * 1%;
    }

    @media only screen and (max-width: 1023px) {
      width: 50%;
    }

    @media only screen and (max-width: 639px) {
      display: none;
      width: 100%;
    }

    &--active {
      margin-left: 0;
    }

    &--active-mobile {
      display: block;
    }

    &__back-button {
      display: flex;
      align-items: center;
      padding: 0 24px;
      font-size: 0.8125rem;
      margin-top: 20px;

      &__icon {
        @include background(color("gray-400"), color("gray-700"));
        @include color(color("gray-700"), color("gray-400"));
        height: 35px;
        width: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(180deg);
        margin-right: 15px;

        @media only screen and (max-height: 610px) {
          height: 25px;
          width: 25px;
        }

        & > svg {
          width: 20px;

          @media only screen and (max-height: 610px) {
            width: 15px;
          }
        }
      }
    }

    &__header {
      @extend #{$menu-main-header};
    }

    &__list {
      @extend #{$menu-main-list};

      &__item {
        @extend #{$menu-main-list-item};
        padding-top: 7px;
        padding-bottom: 7px;

        &--active {
          @extend #{$menu-main-list-item--active};
        }
      }
    }
  }

  &__preview {
    width: 50%;
    @include background(color("gray-700"), color("white"));
    margin-left: -50%;
    transition: margin-left 800ms ease;

    @media only screen and (max-width: 1199px) {
      width: math.div(100, 3) * 1%;
    }

    @media only screen and (max-width: 1023px) {
      width: 0;
    }

    &--active {
      margin-left: 0;
    }

    &__image-wrapper {
      height: 70%;

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__button {
      background: color("emerald-500");
      color: color("white");
      display: inline-block;
      font-weight: 500;
      padding: 13px 24px;
      margin-bottom: 15px;
      text-decoration: none;
    }

    &__description {
      padding: 32px 64px;
      font-size: 1.063rem;
      font-weight: 300;
      line-height: 1.6;
    }
  }
}
