@import "../../tools";

.main {
  flex-grow: 1;
  max-width: 1175px;
  width: 100%;
  margin: 50px auto;

  .section ~ .section {
    margin-top: 40px;
    padding-top: 25px;
    border-top: 1px solid;
    @include border-color(color("gray-700"), color("gray-300"));
  }
}

.section {
  &__title {
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 12px;
  }

  &__setting {
    margin-top: 15px;

    &--radio {
      .inline-label {
        margin-bottom: 5px;
      }
    }
  }
}

.inline-label {
  display: inline-block;
  font-weight: 500;
  margin-right: 0.438rem;
  cursor: inherit;
}

.service-container {
  min-height: 200px;

  &__caption {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &--expanded {
      -webkit-line-clamp: initial;
    }
  }

  &__toggle-button {
    color: color("orange-500");
    font-size: 0.9375rem;
    font-weight: 500;
    cursor: pointer;
  }
}
