@import "../../tools";

.table {
  border: 1px solid;
  @include border-color(color("gray-600"), color("gray-300"));
  border-radius: 3px;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: space-between;
    @include background(color("gray-700"), color("gray-100"));
    padding: 14px 20px;
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;

    &__sort {
      display: flex;
      opacity: 0.9;
      cursor: pointer;
      user-select: none;

      svg {
        margin-left: 5px;
        transform: rotate(90deg);
        opacity: 0.75;
        width: 19px;
        transition: all 250ms;
      }

      &--reversed {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__body {
    &__row {
      display: flex;
      justify-content: space-between;
      padding: 13px 20px;
      border-top: 1px solid;
      @include border-color(color("gray-600"), color("gray-300"));

      @media (max-width: 1000px) {
        flex-direction: column;

        & > *:first-child {
          font-weight: 500;
          opacity: 0.89;
          margin-bottom: 15px;
        }
      }

      @media (max-width: 639px) {
        & > *:first-child {
          margin-bottom: 10px;
        }
      }

      &--empty {
        justify-content: center;
        opacity: 0.6;
      }

      &__actions {
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
        flex-grow: 1;
        gap: 60px;
        @media (max-width: 1000px) {
          justify-content: initial;
          gap: 20px;
        }

        > * {
          flex-shrink: 0 !important;
          flex-grow: 0 !important;
          flex-wrap: nowrap !important;
        }

        > * * {
          white-space: nowrap;
        }
      }
    }
  }
}
