@import "../tools";

@media print {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  #root {
    position: static;
  }

  header,
  aside,
  [class^="main-menu_"],
  [class^="action-row_"],
  [class^="calendar_calendar-container__action-row"] {
    display: none !important;
  }

  [class^="calendar_calendar-container__calendar__"],
  [class^="search-results_search-results__"],
  [class^="news-dashboard_dashboard__"] {
    height: auto !important;
    display: block !important;

    [class^="search-results_column__"],
    [class^="news-column_column__"] {
      min-width: auto !important;
      max-width: initial !important;
      height: auto !important;
      min-height: auto !important;
      background: transparent !important;

      [class^="search-results_column__headline__"],
      [class^="news-column_column__headline__inner___"] {
        width: 100%;

        & > div {
          font-size: 20px;
          text-transform: uppercase;
        }

        [class^="search-results_column__headline__select__"],
        [class^="news-column_column__headline__select__"] {
          display: none !important;
        }
      }

      [class^="search-results_column__scrollable__"],
      [class^="news-column_column__scrollable__"] {
        height: auto !important;
        padding: 0 !important;

        [class^="news-item_item__"],
        [class^="news-item-detailed_item__"] {
          break-inside: avoid;
          width: auto !important;
          max-height: none !important;
          opacity: 1 !important;
        }

        [class^="news-item-detailed_item__"] {
          border-bottom: 1px solid color("black");

          [class^="news-item-detailed_item__title__"] {
            font-size: 20px;
          }

          [class^="news-item-detailed_item__"] {
            border-bottom: none;
          }
        }
      }
    }
  }

  [class^="article-view_article-view__"] {
    min-width: auto !important;
    max-width: initial !important;

    &:not([class*="article-view_article-view__extra"]):not(
        [class*="article-view_article-view__header"]
      ) {
      border: none !important;
    }

    [class^="article-view_article-view__headline__info__"] {
      margin-right: 0;
    }

    [class^="article-view_article-view__headline__actions__"] {
      display: none;
    }

    [class^="article-view_article-view__scrollable__"] {
      height: auto !important;
      padding: 10px;

      [class*="article-view_article-view__header__left__article-details__"] {
        display: flex;
      }

      [class*="article-view_article-view__main-content__article-details__"] {
        display: none;
      }

      [class*="article-view_article-view__extra__"] {
        break-inside: avoid;
      }

      [class*="article-view_article-view__extra__contact__"] {
        display: block;
        background: transparent;
      }

      [class*="article-view_article-view__extra__meta-tags__"] {
        button {
          padding: 0;
          background: transparent;
        }
      }
    }
  }

  .embeddedServiceHelpButton {
    display: none !important;
  }

  body {
    &.article-view-open {
      [class^="search-results_column__headline__"],
      [class^="news-column_column__headline__"] {
        display: none;
      }

      [class^="news-item_item__"],
      [class^="news-item-detailed_item__"] {
        display: none;
      }

      [class*="news-item_item--with-inline-article-view__"] {
        display: block;
        border-left: none;

        [class^="news-item_item__article-view-wrapper__"] {
          display: block;
          position: static;
          height: auto !important;

          & > [class^="article-view_article-view__"] {
            position: static;
          }
        }
      }
    }

    &.modal-article-view-open {
      #root {
        display: none;
      }

      [class^="modal_modal-backdrop__"] {
        background: transparent !important;
        position: static;
        display: block;

        & > .article-view {
          border: none !important;
          box-shadow: none !important;
          height: auto;
        }
      }
    }
  }
}
