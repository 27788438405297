@import "../../tools";

.popover-menu {
  padding: 5px 0;

  &__header {
    padding: 15px 15px;
    font-size: 0.75rem;
    text-transform: uppercase;
    @include color(color("gray-500"), color("gray-600"));
  }

  &__item {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    cursor: pointer;
    @include color(color("gray-300"), color("gray-700"));
    transition: background 250ms;

    &:hover {
      @include background(color("gray-600"), color("gray-200"));
    }
  }

  &__divider {
    height: 1px;
    margin: 5px 0;
    @include background(color("gray-800"), color("gray-300"));
  }
}
