@import "../tools";

.user-menu {
  position: relative;

  &__trigger {
    // Reset button style
    font-family: inherit;
    font-size: inherit;
    border: none;
    text-align: left;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    color: inherit;

    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:focus-visible {
      outline: 2px solid;
      @include outline-color(color("white"), color("black"));
      border-radius: 2px;
    }

    &:hover {
      @include color(color("white"), color("black"));
    }

    &__user-icon {
      width: 25px;
      margin-right: 10px;

      @media screen and (max-width: 900px) {
        margin-right: 0;
      }
    }

    &__login-icon {
      width: 38px;
      margin-right: 10px;

      @media screen and (max-width: 900px) {
        margin-right: 0;
      }
    }

    &__name {
      white-space: nowrap;

      @media screen and (max-width: 900px) {
        display: none;
      }
    }
  }

  &__dropdown {
    position: absolute;
    @include background(color("gray-600"), color("white"));
    margin-top: 10px;
    padding: 5px 0;
    right: 0;
    min-width: 280px;
    box-shadow: 0 0 8px 0 rgba(32, 36, 53, 0.2);

    &__item {
      display: flex;
      @include color(color("gray-100"), color("gray-700"));
      padding: 10px 30px;
      text-decoration: none;
      cursor: pointer;
      transition: background 250ms;

      & > div {
        flex-grow: 1;
      }

      img {
        width: 22%;
        float: left;
        margin-right: 10px;
      }

      svg {
        flex-shrink: 0;
        width: 100%;
        max-width: 20px;
      }

      &:hover {
        @include background(color("gray-500"), color("gray-100"));
      }
    }

    &__divider {
      height: 1px;
      @include background(color("gray-700"), color("gray-200"));
      margin: 5px 0;
    }

    &__text {
      padding: 15px 30px 15px 20px;
      color: color("gray-500");
      font-size: 0.75rem;
      text-transform: uppercase;
    }
  }
}
