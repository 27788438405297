@import "../../tools";

.media-preview-modal {
  @include background(color("gray-900"), color("white"));
  padding: 0;
  max-height: calc(90vh);
  height: 100%;
  max-width: 85%;

  &__loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  iframe {
    height: 100%;
    display: block;
  }
}
