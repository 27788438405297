@import "../../global/config";
@import "../../tools";

$padding: 8px;

.search-results {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(
    100vh - #{$menu-height + $action-row-height + $calendar-action-row-height + ($padding * 2)}
  );

  &.news {
    height: calc(100vh - #{$menu-height + $action-row-height + ($padding * 2)});
  }
  margin: $padding 0;

  @media only screen and (max-width: 639px) {
    $height: calc(#{$mobile100vh} - #{$menu-height + $action-row-height + ($padding * 2)});

    position: relative;
    height: $height;
    &.news {
      height: $height;
    }
  }
}

.column {
  flex-grow: 0;
  min-width: 600px;
  max-width: 300px;
  @include background(color("gray-700"), color("gray-200"));
  margin: 0 5px;
  border-radius: 4px;
  height: 100%;
  min-height: 200px;

  @media only screen and (max-width: 639px) {
    min-width: calc(100vw - 10px);
    max-width: calc(100vw - 10px);
  }

  &__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: $column-headline-height;
    font-size: 14px;
    @include color(color("gray-300"), color("black"));

    &__select {
      $select: &;

      :global {
        #{$select} {
          &__control {
            min-width: 135px;
            min-height: auto;
            border: none;
            background-color: transparent;
            box-shadow: none;
          }

          &__menu {
            border-radius: 0;
          }

          &__menu-list {
            padding: 0;
          }

          &__option {
            text-transform: uppercase;
            font-size: 12px;
            @include background(color("gray-700"), color("white"));

            &--is-focused {
              @include background(color("gray-600"), color("gray-200"));
            }

            &--is-selected {
              @include background(color("gray-900"), color("gray-600"));
            }
          }

          &__value-container {
            justify-content: flex-end;
            padding: 0;
          }

          &__single-value {
            text-transform: uppercase;
            font-size: 12px;
            @include color(color("gray-300"), color("gray-700"));
          }

          &__indicator-separator {
            display: none;
          }

          &__dropdown-indicator {
            padding: 0;
          }
        }
      }
    }

    &__title {
      &--clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - #{$column-headline-height});
    @include background(color("gray-800"), color("white"));
    @include border-color(color("gray-700"), color("gray-200"));
    border: 10px solid;
    border-top: none;
  }

  &__scrollable {
    overflow: auto;
    height: calc(100% - #{$column-headline-height});
    padding: 0 10px 10px;

    &.column__scrollable--has-scrollbar {
      padding-right: 0;
    }
  }

  &--single {
    // min-width: calc(100% - 5px);
    max-width: none;
    margin-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .column__headline {
      justify-content: normal;

      &__select {
        margin-left: 75px;
      }
    }

    .column__scrollable {
      display: flex;
      flex-flow: column wrap;
      align-content: flex-start;
      padding: 0 5px 5px;
      max-width: 630px;
      overflow-x: hidden;

      & > div,
      & > button {
        width: 600px;
        margin-left: 5px;
        margin-right: 5px;
        flex-grow: 1;
        max-height: 160px;
      }
    }
  }

  &--workInProgress {
    @include background("inherit", color("signal-yellow-400"));

    .loading {
      @include border-color("inherit", color("signal-yellow-400"));
    }
  }

  &--updated {
    @include background("inherit", color("emerald-300"));

    .loading {
      @include border-color("inherit", color("emerald-300"));
    }
  }

  &--published {
    @include background("inherit", color("emerald-500"));

    .column__headline {
      @include color(color("gray-300"), color("white"));

      &__select {
        @include color(color("gray-300"), color("black"));

        :global {
          .column__headline__select__single-value {
            @include color(color("gray-300"), color("white"));
          }
        }
      }
    }

    .loading {
      @include border-color("inherit", color("emerald-500"));
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - #{$column-headline-height});
  @include background(color("gray-800"), color("white"));
  @include border-color(color("gray-700"), color("gray-200"));
  border: 10px solid;
  border-top: none;
}
